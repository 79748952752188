import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from "feather-icons-react";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links1: [
      { link: "#", title: "About Us" },
      { link: "#", title: "Media & Press" },
      { link: "#", title: "Career" },
      { link: "#", title: "Blog" },
    ],
    links2: [
      { link: "#", title: "Pricing" },
      { link: "#", title: "For Marketing" },
      { link: "#", title: "For CEOs" },
      { link: "#", title: "For Agencies" },
      { link: "#", title: "Our Apps" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col>
                <div>
                  <h5 className="mb-4 footer-list-title">Contacto</h5>
                  <Row>
                    <Col lg={3} sm={6}>
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <p>9 de Julio 421, PA, Tandil</p>
                      </Media>
                    </Col>
                    <Col lg={3} sm={6}>
                      <i>
                        <FeatherIcon
                          icon="message-circle"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <p>(0249) 154638490</p>
                      </Media>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
