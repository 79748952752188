import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from 'feather-icons-react';

import image1 from '../../assets/images/photos/owndesk_93.jpg'
import image2 from '../../assets/images/photos/owndesk_73.jpg'
import image3 from '../../assets/images/photos/owndesk_68.jpg'
import image4 from '../../assets/images/photos/owndesk_70.jpg'
import image5 from '../../assets/images/photos/owndesk_16.jpg'
import image6 from '../../assets/images/photos/owndesk_38.jpg'
import image7 from '../../assets/images/photos/owndesk_15.jpg'
import image8 from '../../assets/images/photos/owndesk_112.jpg'

class Gallery extends Component {
    state = {
        links1 : [
            { link : "#", title : "About Us" },
            { link : "#", title : "Media & Press" },
            { link : "#", title : "Career" },
            { link : "#", title : "Blog" },
        ],
        links2 : [
            { link : "#", title : "Pricing" },
            { link : "#", title : "For Marketing" },
            { link : "#", title : "For CEOs" },
            { link : "#", title : "For Agencies" },
            { link : "#", title : "Our Apps" },
        ],
    }
    render() {
        return (
          <>
            <Container id="gallery" className="gallery">
              <Row noGutters>
                <Col lg={6}>
                  <img src={image1} />
                </Col>
                <Col lg={6}>
                  <img src={image2} />
                </Col>
              </Row>
              <Row noGutters>
                <Col lg={6}>
                  <img src={image4} />
                </Col>
                <Col lg={6}>
                  <img src={image3} />
                </Col>
              </Row>
              <Row noGutters>
                <Col lg={6}>
                  <img src={image5} />
                </Col>
                <Col lg={6}>
                  <img src={image6} />
                </Col>
              </Row>
              <Row noGutters>
                <Col lg={6}>
                  <img src={image7} />
                </Col>
                <Col lg={6}>
                  <img src={image8} />
                </Col>
              </Row>
            </Container>
          </>
        );
    }
}

export default Gallery;

