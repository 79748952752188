import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from './pricing-box';

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricings1: [
                {
                    title: "Own by Day",
                    features: [
                        { title: "Escritorio designado", value: 'No' },
                        { title: "Acceso", value: "Uso por el día" },
                    ]
                },
                {
                    title: "Basic Owner",
                    features: [
                        { title: "Escritorio designado", value: 'Si' },
                        { title: "Acceso", value: "Libre, con token" },
                    ]
                },
                {
                    title: "Office Owner",
                    features: [
                        { title: "Oficina exclusiva", value: 'Hasta 6 personas' },
                        { title: "Acceso", value: "Libre, con token" },
                        { title: "Créditos para sala de conferencias", value: "Consultar" }
                    ]
                },
            ],
            pricings2: [
                {
                    title: "Economy", price: 19, duration: "Year",
                    features: [
                        { title: "Bandwidth", value: "1GB" },
                        { title: "Onlinespace ", value: "50MB" },
                        { title: "Support", value: "No" },
                    ]
                },
                {
                    title: "Premium", price: 29, duration: "Year",
                    features: [
                        { title: "Bandwidth", value: "2GB" },
                        { title: "Onlinespace ", value: "1GB" },
                        { title: "Support", value: "No" },
                    ]
                },
                {
                    title: "Developer", price: 39, duration: "Year",
                    features: [
                        { title: "Bandwidth", value: "3GB" },
                        { title: "Onlinespace ", value: "2GB" },
                        { title: "Support", value: "Yes" },
                    ]
                },
            ],
            activeTab: '1',
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                        <SectionTitle
                            subtitle="Planes"
                            title="Elegí tu Plan"
                        />

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row style={{ justifyContent: 'space-evenly' }}>
                                    <PricingBox pricings={this.state.pricings1} />
                                </Row>
                            </TabPane>
                            {/* <TabPane tabId="2">
                                <Row>
                                    <PricingBox pricings={this.state.pricings2} />
                                </Row>
                            </TabPane> */}
                        </TabContent>

                    </Container>

                </section>
            </React.Fragment >
        );
    }
}

export default Pricing;