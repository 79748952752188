import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          icon: "user",
          title: "Escritorio Propio",
          desc:
            "Lo que nos caracteriza e identifica. Escritorios individuales de diseño exclusivo con sillas ergonómicas, pensados para vos."
        },
        {
          icon: "users",
          title: "Oficinas Privadas",
          desc:
            "Te ofrecemos un lugar único y a la medida de las necesidades de tu equipo."
        },
        {
          icon: "calendar",
          title: "Salas de Reuniones",
          desc:
            "Todo lo que precisás para llevar a cabo tus exitosas reuniones: TV 50 pulgadas, Chromecast, Pizarra y mucho más."
        },
        {
          icon: "music",
          title: "Areas de Recreación",
          desc: "Cocina completamente equipada y un cómodo salón comedor"
        },
        {
          icon: "layers",
          title: "Addons",
          desc:
            "Si estás pensando en mejorar tu experiencia de trabajo y performance, tenemos lo que buscás."
        }
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="services">
          <Container>
            <SectionTitle subtitle="Servicios" title="Nuestros Servicios" />

            <Row>
              <ServiceBox services={this.state.services} />
            </Row>

            {/* <Row className="mt-4">
                    <Col lg={12}>
                        <div className="text-center">
                            <Link to="#" className="btn btn-success">View more</Link>
                        </div>
                    </Col>
                </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
