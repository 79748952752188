import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import bg1 from "../../assets/images/photos/6-BLUR.jpg";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="main-banner hero-section-5"
          id="home"
          style={{ background: `url(${bg1})`, backgroundSize: "cover" }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="hero-wrapper text-left mb-4">
                  <h1 className="hero-title text-bold text-white">Tandil</h1>
                  <h1 className="hero-title text-white">tiene un nuevo</h1>
                  <h1 className="hero-title text-blue text-bold">
                    Coworking Space
                  </h1>
                </div>

                <div className="hero-wrapper text-left mb-4">
                  <a
                    href="https://wa.me/5492494638490?text=Me%20gustar%C3%ADa%20reservar%20mi%20espacio%20en%20Owndesk"
                    className="btn btn-primary btn-lg"
                    target="_blank"
                  >
                    Reservar
                  </a>
                </div>
              </Col>
              <Col lg={6}></Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
